<template>
    <PatientListCompo/>
</template>

<script>
export default {
    components: {
        PatientListCompo: () => import('@/components/patientListCompo.vue'),
    },
    inject: ["setRightSideHidden"],
    props: {},
    data() {
        return {};
    },
    watch: {},
    created() {
    },
    mounted() {
        this.setRightSideHidden(false);
        this.initLocalStorage();
    },
    destroyed() {
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/app.scss";

.tbl tbody tr{
    &:hover{
        //background: var(--color-p99);
        cursor: pointer;
    }
}
.srhTop button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
.srhTop button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
  opacity: 0.8;
}
</style>

